import React from "react";
import { useImpressumData } from "../query/impressumQuery";
import TextCard from "../components/textCard/TextCard";

const Impressum = () => {
  const { impressumData } = useImpressumData();
  return (
    <div className="page impressum">
      <TextCard pageTitle={true} headTitle={false} title={impressumData.title} html={impressumData.description} />
    </div>
  );
};

export default Impressum;
