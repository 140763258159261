import { graphql, useStaticQuery } from "gatsby";
import { PrismicSliceManipulation } from "../utils/PrismicSliceManipulation";

export const useImpressumData = () => {
  const impressumDataRaw = useStaticQuery(rawData).allPrismicPage.nodes[0];

  const impressumData = PrismicSliceManipulation(impressumDataRaw.data.body)[0];

  const metaDescription = impressumDataRaw.data.metadescription.text;
  const metaTitle = impressumDataRaw.data.metatitle.text;

  return { impressumData, metaDescription, metaTitle };
};

const rawData = graphql`
  {
    allPrismicPage(filter: { data: { title: { text: { eq: "Impressum" } } } }) {
      nodes {
        id
        data {
          metadescription {
            text
          }
          metatitle {
            text
          }
          title {
            text
          }
          body {
            ... on PrismicPageDataBodyTextbox {
              id
              slice_type
              primary {
                description {
                  html
                }
                textboxtitle {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
